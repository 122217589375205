import datepicker from 'flowbite/dist/datepicker';

function resetSearchForm(){
    document.getElementById('searchKey').value = 'fullText';
    document.getElementById('searchValue').value = null;
    document.getElementById('articleType').value = '';
    document.getElementById('pf').value = null;
    document.getElementById('pt').value = null;
}

let searchNavButton = document.getElementById("searchMegaMenu-button");
searchNavButton.addEventListener("click", resetSearchForm);
